import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { UserProvider } from './helpers/UserContext';
import { LoadingProvider } from './helpers/LoadingContext.js';
import { DeviceProvider } from './helpers/DeviceContext';
import { eventEmitter } from './helpers/eventEmitter';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Helper to save log messages to localStorage
function saveMessageToLocalStorage(message) {
  const storageKey = 'last100Logs';
  let logs = JSON.parse(localStorage.getItem(storageKey)) || [];

  // Add the new message to the array
  logs.push(message);

  // Keep only the last 100 messages
  if (logs.length > 100) {
    logs = logs.slice(logs.length - 100);
  }

  // Save the updated array back to localStorage
  localStorage.setItem(storageKey, JSON.stringify(logs));
}

// Register and handle messages from the service worker
if ('serviceWorker' in navigator) {
  console.log("Checking installed Service Worker");
  navigator.serviceWorker.register('/service-worker.js', { scope: '/' })
    .then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
      registration.update();

      // Setup listener for service worker messages
      navigator.serviceWorker.addEventListener('message', event => {
        if (event.data.action === 'deregister') {
          // Deregister all service worker registrations
          navigator.serviceWorker.getRegistrations().then(function(registrations) {
            registrations.forEach(registration => {
              registration.unregister().then(success => {
                console.log('Deregistration successful:', success);
              });
            });
          });
        } else if (event.data.action === 'reload') {
          // Reload the page
          window.location.reload(true);
        } else if (event.data.action === 'showLoading') {
          eventEmitter.emit('show-loading');
        } else if (event.data.action === 'hideLoading') {
          eventEmitter.emit('hide-loading');
        } else {
          // Log any other messages sent from the service worker
          console.log(event.data);
          saveMessageToLocalStorage(event.data.message);
        }
      });
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}

root.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <DeviceProvider>
        <UserProvider>
          <LoadingProvider>
            <App />
          </LoadingProvider>
        </UserProvider>
      </DeviceProvider>
    </Suspense>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
