import React, { useState, useEffect } from 'react';
import { useUser } from './helpers/UserContext.js';
import { useLoading } from './helpers/LoadingContext';
import { useHistory, useLocation } from 'react-router-dom';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonToast, IonContent, IonInput, IonButtons, IonButton, IonText, IonItem, IonFooter, IonIcon, IonImg, IonLabel } from '@ionic/react';
import { updateUserSettings } from './helpers/updateUserSettings.js';
import createLogger from './helpers/logging';
import { subscribeToPush } from './actions/subscribeToPush';
import { arrowBack } from 'ionicons/icons';
import { t } from 'i18next';

const LoginForm = () => {
  const { indexlog, indexerror } = createLogger('LoginForm');
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [toast, setToast] = useState({ message: '', isOpen: false });
  const { setUser, setError, error, getCookie, parseJwt, checkPushPermission } = useUser();
  const { showLoading, hideLoading } = useLoading();
  const redirect = useHistory();
  const location = useLocation();

  useEffect(() => {
    // Clear the login fields when the component mounts or location changes
    setLoginEmail('');
    setLoginPassword('');
    setError('');
  }, [location, setError]);

  const handleLogin = async (event) => {
    event.preventDefault();
    showLoading(t('pleaseWait'));

    try {
        const eMail = loginEmail.toLowerCase();

        const response = await fetch('/api/login', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ loginEmail: eMail, loginPassword }),
        });

        const user = await response.json();
        if (!response.ok) {
            throw new Error(user.error || t('loginFailed'));
        }

        indexlog('Login successful, received userData:', user);

        const lastVisitDate = new Date(user.lastVisit);
        const now = new Date();
        const fifteenMinutes = 15 * 60 * 1000;
        if (now - lastVisitDate > fifteenMinutes) {
            const currentTimestamp = now.toISOString();
            await updateUserSettings('lastVisit', currentTimestamp, getCookie, parseJwt);
        }

        setUser(user);
        storeTokenInCookie(user.token);
        setError('');

        //indexlog(user);

        if (user.pushNotificationSubscription) {
          if ("Notification" in window) {
            const permission = await Notification.requestPermission();
            indexlog('Requested Push Notification Permission:', permission);

            if (permission === 'granted') {    
                try {
                    const updatedSubscriptions = await subscribeToPush(user);
                    if (updatedSubscriptions) {
                        await updateUserSettings('subscriptionDetails', updatedSubscriptions, getCookie, parseJwt);
                        await updateUserSettings('pushNotificationSubscription', true, getCookie, parseJwt);
                        setUser(prevUser => ({
                        ...prevUser,
                        subscriptionDetails: updatedSubscriptions,
                        pushNotificationSubscription: true
                        }));
                    }
                } catch (error) {
                    indexerror('Error subscribing to push notifications:', error);
                }
            }
          }
          checkPushPermission();
        }

        redirect.push('/');
    } catch (error) {
        indexerror('Error during login: ' +error+' Message: '+error.message);
        setError(error.message || t('loginFailed'));
    } finally {
        hideLoading();
    }
  };

  const storeTokenInCookie = (token) => {
    const now = new Date();
    now.setTime(now.getTime() + 24 * 60 * 60 * 1000);
    document.cookie = `jwtToken=${token}; expires=${now.toUTCString()}; path=/; Secure; SameSite=Strict`;
  };

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    if (!loginEmail) {
      setError(t('enterEmail'));
      return;
    }

    const eMail = loginEmail.toLowerCase();

    try {
      showLoading(t('processingForgotPasswordRequest'));
      const response = await fetch('/api/forgotPassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: eMail }),
      });

      if (response.ok) {
        setToast({
          message: 'A push message has been sent to your registered devices. Click it to get logged in.',
          isOpen: true
        });
        setError('');
        redirect.push('/');
      } else {
        const errorData = await response.json();
        setError(errorData.error);
      }
    } catch (error) {
      indexerror('Error handling forgot password:', error);
      setError(t('errorOccurred'));
    } finally {
      hideLoading();
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
              <IonImg 
                src="/ping-alive-logo/logo192.png" 
                alt="logo-image" 
                style={{ height: '64px'}}
              />
              
          </IonTitle>
        </IonToolbar>       
      </IonHeader>
      <IonToast
        isOpen={toast.isOpen}
        message={toast.message}
        duration={2000}
        onDidDismiss={() => setToast({ message: '', isOpen: false })}
        position="middle"
        layout="stacked"
        swipeGesture="vertical"
      />
      <IonContent>
        <IonItem>
          <IonLabel><strong>{t('login')}</strong></IonLabel>
        </IonItem>
        <form onSubmit={handleLogin}>
          <IonItem>
            <IonInput
              type="email"
              value={loginEmail}
              clearInput={true}
              onIonInput={(e) => setLoginEmail(e.detail.value)}
              placeholder={t('email')}
              required
            />
          </IonItem>
          <IonItem>
            <IonInput
              type="password"
              value={loginPassword}
              clearInput={true}
              onIonInput={(e) => setLoginPassword(e.detail.value)}
              placeholder={t('password')}
              required
            />
          </IonItem>
          {error && <IonText color="danger">{error}</IonText>}
          <IonButton expand="block" type="submit">{t('login')}</IonButton>
          <IonButton expand="block" type="button" fill="clear" onClick={handleForgotPassword}>{t('forgotPassword')}</IonButton>
        </form>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton fill="outline" onClick={() => redirect.push('/', { fromLoginPageBackButton: true })}>
              {t('back')}
              <IonIcon slot="start" icon={arrowBack}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default LoginForm;
