// DeviceContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import UAParser from 'ua-parser-js';
//import useLogger from './useLogger';

const DeviceContext = createContext();

export const useDevice = () => useContext(DeviceContext);

export const DeviceProvider = ({ children }) => {
  const [deviceInfo, setDeviceInfo] = useState({});
  //const { indexlog } = useLogger('DeviceContext');

  useEffect(() => {
    const parser = new UAParser();
    const result = parser.getResult();
    const deviceCapabilities = {
      device: result.device.model || 'Unknown',
      vendor: result.device.vendor || 'Unknown',
      type: result.device.type || 'computer',
      os: `${result.os.name} ${result.os.version}`,
      browser: `${result.browser.name} ${result.browser.version}`,
      engine: result.engine.name || 'Unknown',
      isStandalone: window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone,
    };
    setDeviceInfo(deviceCapabilities);
    //indexlog("DeviceInfo: Device "+deviceCapabilities.device+", Vendor "+deviceCapabilities.vendor+", Type "+deviceCapabilities.type+", OS "+deviceCapabilities.os+", Browser "+deviceCapabilities.browser+", Engine "+deviceCapabilities.engine+", Standalone "+deviceCapabilities.isStandalone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <DeviceContext.Provider value={deviceInfo}>
      {children}
    </DeviceContext.Provider>
  );
};

