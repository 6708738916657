import { updateUserSettings } from '../helpers/updateUserSettings';

const handleAliveButtonClick = async (setUser, getCookie, parseJwt) => {
  try {
    const now = new Date();
    const currentTimestamp = now.toISOString();
    await updateUserSettings('lastVisit', currentTimestamp, getCookie, parseJwt);
    await updateUserSettings('userStatus', 0, getCookie, parseJwt);

    setUser((prevUser) => ({ ...prevUser, userStatus: 0 }));
  } catch (error) {
    console.error('Error updating user status:', error);
  }
};

export default handleAliveButtonClick;