import React from 'react';
import '../css/AndroidInstallInstructionsOverlay.css'; // Make sure to style your overlay
import { useTranslation } from 'react-i18next';

const AndroidInstallInstructionsOverlay = ({ onClose }) => {
  const { t } = useTranslation();
  
  return (
    <div className="overlay">
      <div className="overlay-content">
        <h2>{t('andrInst.1')}</h2>
        <h3>{t('andrInst.2')}</h3>
        <p>{t('andrInst.3')}</p>
        <ol>
          <li>{t('andrInst.4')}</li>
          <li>{t('andrInst.5')}</li>
          <li>{t('andrInst.6')}</li>
        </ol>
      </div>
    </div>
  );
};

export default AndroidInstallInstructionsOverlay;
