import React, { useState } from 'react';
import { IonItem, IonInput, IonButtons, IonButton, IonToolbar, IonContent, IonText, IonModal, IonHeader, IonTitle } from '@ionic/react';
import { useUser } from './UserContext';
import addToUserList from '../actions/addToUserList';
import { useTranslation } from 'react-i18next';

const UserInput = ({ arrayName, setNewAlertId, setNewAlertLabel, setNewMonitorId, setNewMonitorLabel }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const { user, setUser, getCookie, parseJwt } = useUser();
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const [showMoreInviteSteps, setShowMoreInviteSteps] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(null); // Clear error message when the input changes
  };

  const handleSearch = async () => {
    // Basic email pattern check
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError(t('userInput.noEmailAddress'));
      return;
    }

    try {
      const token = getCookie('jwtToken');
      const eMail = email.toLowerCase();
      const response = await fetch('/api/findUUID', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ email: eMail }),
      });

      if (response.ok) {
        setError('');
        const data = await response.json();
        await addToUserList(
          arrayName,
          data.uuid,
          eMail, // Using email as the label
          getCookie,
          parseJwt,
          setUser,
          setNewAlertId,
          setNewAlertLabel,
          setNewMonitorId,
          setNewMonitorLabel,
          user
        );
        setEmail(''); // Clear the input
      } else if (response.status === 404) {
        setShowInviteUserModal(true);
        setError(t('userInput.userNotFound'));
      } else {
        setError(t('userInput.searchError'));
      }
    } catch (error) {
      console.error('Error:', error);
      setError(t('userInput.serverError'));
    }
  };

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const copyTextToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(t('userInput.text1'));
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <div className="user-input">
      <IonItem>
        <IonInput aria-label={t('enterEmailAddress')} placeholder={t('enterEmailAddress')}
          type="email"
          inputmode="email"
          clearInput={true}
          value={email}
          onIonInput={handleEmailChange}
          onKeyDown={onEnter}
          required
        />
        <IonButton onClick={handleSearch} expand="block">
          {t('add')}
        </IonButton>
      </IonItem>
      {showInviteUserModal && (
        <IonModal isOpen={showInviteUserModal}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>{t('userInput.inviteNewUserHeader')}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => {
                  setShowInviteUserModal(false);
                  setShowMoreInviteSteps(false);
                  }}>{t('close')}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonItem><p>{t('userInput.intro')}</p></IonItem>
            <IonItem><p>{t('userInput.step1')}</p></IonItem>
            <IonItem><p>{t('userInput.text1')}</p>
              <IonButton onClick={copyTextToClipboard} fill="clear">
                {t('copy')}
              </IonButton>
            </IonItem>
            {!showMoreInviteSteps && (
              <IonItem>
                <IonButton onClick={() => setShowMoreInviteSteps(true)} fill="clear">
                {t('userInput.showMoreInviteSteps')}
                </IonButton>
              </IonItem>
            )}
            {showMoreInviteSteps && (
              <>
                <IonItem><p>{t('userInput.step2')}</p></IonItem>
                <IonItem><p>{t('userInput.step3')}</p></IonItem>
                <IonItem><p>{t('userInput.step4')}</p></IonItem>
                <IonItem><p>{t('userInput.step5')}</p></IonItem>
                <IonItem><p>{t('userInput.paste1')}</p></IonItem>
                <IonItem><p>{t('userInput.paste2')}</p></IonItem>
                <IonItem><p>{t('userInput.step6')}</p></IonItem>
                <IonItem><p>{t('userInput.step7')}</p></IonItem>
              </>
            )}
          </IonContent>
        </IonModal>
      )}
      {error && (
        <IonItem>
          <IonText color="danger">
            <p>{error}</p>
          </IonText>
        </IonItem>
      )}
    </div>
  );
};

export default UserInput;
