import React, { useState, useRef } from 'react';
import { IonButton, IonIcon, IonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';  // Import useTranslation
import { helpCircleOutline } from 'ionicons/icons';

const HelpPopover = ({ keyword }) => {
    const { t } = useTranslation(); // Initialize translation hook
    const [toastOpen, settoastOpen] = useState(false);
    const buttonRef = useRef();

    return (
        <>
            <IonButton fill="clear" id={keyword} ref={buttonRef} onClick={() => settoastOpen(true)}>
                <IonIcon slot="icon-only" icon={helpCircleOutline}></IonIcon>
            </IonButton>
            <IonToast
                isOpen={toastOpen} 
                trigger={keyword} 
                message={t(keyword)} 
                duration={12000}
                position="top"
                positionAnchor="header"
                layout="stacked"
                swipeGesture="vertical"
                onDidDismiss={() => settoastOpen(false)}>
            </IonToast>
        </>
    );
};

export default HelpPopover;
