import React, { useEffect, useRef } from 'react';
import '../css/iPadInstallInstructionsOverlay.css';
import { useTranslation } from 'react-i18next';

const IPadInstallInstructionsOverlay = ({ onClose }) => {
  const { t } = useTranslation();
  const overlayRef = useRef(null);

  useEffect(() => {
    const overlay = overlayRef.current;
    let touchTimer = null;

    const handleTouchStart = () => {
      touchTimer = setTimeout(() => {
        onClose();
      }, 5000);
    };

    const handleTouchEnd = () => {
      clearTimeout(touchTimer);
    };

    // Add event listeners to the overlay element
    overlay.addEventListener('touchstart', handleTouchStart);
    overlay.addEventListener('touchend', handleTouchEnd);
    overlay.addEventListener('touchcancel', handleTouchEnd);

    // Cleanup function to remove event listeners when the component unmounts
    return () => {
      overlay.removeEventListener('touchstart', handleTouchStart);
      overlay.removeEventListener('touchend', handleTouchEnd);
      overlay.removeEventListener('touchcancel', handleTouchEnd);
    };
  }, [onClose]);

  return (
    <div className="overlay" ref={overlayRef}>
      <div className="overlay-content">
        <h2>{t('iPadInst.1')}</h2>
        <h3>{t('iPadInst.2')}</h3>
        <p>{t('iPadInst.3')}</p>
        <ol>
          <li>{t('iPadInst.4')}</li>
          <img src="/img/ShareIcon.png" alt="Share Icon" />
          <li>{t('iPadInst.5')}</li>
          <li>{t('iPadInst.6')}</li>
          <li>{t('iPadInst.7')}</li>
        </ol>
      </div>
    </div>
  );
};

export default IPadInstallInstructionsOverlay;

