import React from 'react';
import { IonList, IonItem, IonLabel } from '@ionic/react';

const PromoDetails = ({ promoDetails, registrationDate }) => {
    // Default settings
    const defaultTestPeriod = 1;  // Default test period is 1 month
    const defaultPrice = 1.29;    // Default price is €1.29 per month

    // Function to calculate and format the end date of the test period
    const calculateTestPeriodEndDate = (registrationTimeStamp, testMonths) => {
        if (!registrationTimeStamp) {
            return 'Registration date missing';
        }

        const date = new Date(parseInt(registrationTimeStamp, 10));
        date.setMonth(date.getMonth() + testMonths);
        return date.toLocaleDateString();
    };

    // Verify and prepare the data
    const testPeriodMonths = promoDetails ? defaultTestPeriod + (parseInt(promoDetails.freeUsage, 10) || 0) : defaultTestPeriod;
    const price = promoDetails && promoDetails.price ? 
                  (defaultPrice * (promoDetails.price / 100)).toFixed(2) : 
                  defaultPrice.toFixed(2);
    const testPeriodEndDate = calculateTestPeriodEndDate(registrationDate, testPeriodMonths);

    return (
        <IonList>
            <IonItem>
                <IonLabel>Test Period Ends</IonLabel>
                {testPeriodEndDate}
            </IonItem>
            <IonItem>
                <IonLabel>Price</IonLabel>
                €{price} per month
            </IonItem>
        </IonList>
    );
};

export default PromoDetails;
